import React, { useContext, useEffect } from 'react'
import { graphql, Link } from 'gatsby'

import AppContext from '../components/AppContext'
import ProductsList from '../components/ProductsList'
import SEO from '../components/SEO'

import isAuthenticated from '../utils/isAuthenticated'
import { setRedirect } from '../utils/setRedirect'

const CategoryMain = ({ premios, category }) => (
  <>
    <section className="w-4/5 lg:w-4/6 mx-auto mt-10">
      <div className="mx-auto">
        <h3 className="font-bold block text-pxblue-700 text-4xl pb-4 border-b border-pxred">
          Catálogo {category.name}
        </h3>
      </div>
      <div className="pt-4 pb-12">
        <div className="flex flex-wrap items-center">
          {premios.nodes.length > 0 ? (
            <ProductsList premios={premios.nodes} />
          ) : (
            <p>No hay premios</p>
          )}
        </div>
      </div>
    </section>
  </>
)

async function checkTocken(cb) {
  const validToken = await isAuthenticated('user')
  cb(validToken)
}
export default function CategoryPage({ data: { category, premios } }) {
  const { user } = useContext(AppContext)
  const [loggedUser, setLoggedUser] = user
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (loggedUser) {
        checkTocken(setLoggedUser)
      } else {
        // eslint-disable-next-line no-restricted-globals
        setRedirect(location ? location.href : '')
        // navigate('/login')
      }
    }
  })
  return (
    <main>
      <SEO title="" />
      {loggedUser ? (
        <CategoryMain premios={premios} category={category} />
      ) : (
        <p className="w-11/12 mx-auto mt-10 md:w-1/2 lg:w-1/3 border-pxblue-600 border p-4 bg-gray-100 rounded-md text-pxtx-200 text-center">
          <Link to="/login" className="font-medium text-pxblue underline">
            Inicia sesión
          </Link>{' '}
          para ver el contenido
        </p>
      )}
    </main>
  )
}

export const query = graphql`
  query($slug: String!) {
    category: sanityCategory(slug: { current: { eq: $slug } }) {
      name
    }
    premios: allSanityPremio(
      filter: { model: { elemMatch: { slug: { current: { eq: $slug } } } } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        description
        points
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`
