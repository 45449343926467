import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const PremioRender = (premio) => {
  const {
    premio: { name, points, slug, image },
  } = premio
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white py-6 px-2 mb-8 rounded">
      <div className="ProductImageWrapper">
        <Link
          to={`/premio/${slug ? slug.current : ''}`}
          className="inline-block"
        >
          <div className="ProductImage">
            {image ? (
              <GatsbyImage
                image={image.asset.gatsbyImageData}
                alt=""
                className="w-full object-cover"
              />
            ) : null}
          </div>
        </Link>
      </div>
      <p className="text-pxblue-700 text-sm my-2 text-center px-6 h-10 flex items-center  overflow-ellipsis">
        {name}
      </p>
      <span className="block text-pxblue-700 font-bold my-4">
        {points ? `${points} Puntos` : '---'}
      </span>
      <Link
        to={`/premio/${slug ? slug.current : ''}`}
        className="text-white bg-pxblue-700 rounded-lg px-10 py-2 inline-block hover:bg-pxblue-600 hover:shadow-xl"
      >
        Obtener
      </Link>
    </div>
  )
}
export default function Product({ premio }) {
  return premio ? <PremioRender premio={premio} /> : null
}
