export function setRedirect(url) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(`redirect-url`, url)
  }
}

export function removeRedirect() {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(`redirect-url`)
  }
}
