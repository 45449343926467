import React from 'react'
import Product from './Product'

export default function ProductsList({ premios }) {
  return (
    <>
      {premios.map((premio) =>
        premio ? (
          <div
            key={`premio-${premio.slug.current}`}
            className="w-full mb-4 md:w-1/2 lg:w-1/3"
          >
            <Product premio={premio} />
          </div>
        ) : null
      )}
    </>
  )
}
